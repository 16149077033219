<template>
  <b-card-code>
    <!-- form -->
    <validation-observer ref="simpleRules">
      <form ref="contact_information_form">
        <img v-if="home_page.image" :src="home_page.image" alt="" width="300" height="200"/>
        <b-row>
          <b-col md="6">
            <b-card-text>
              <span>{{ $t('Title')}} </span>
            </b-card-text>
            <b-form-group>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('Title')"
                  rules="required"
              >
                <b-form-input name="title"
                    v-model="home_page.title"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('Write title here')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-card-text>
              <span>{{ $t('Image') }} </span>
            </b-card-text>
            <b-form-group>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('Image')"
                  :rules="home_page.image?null:'required'"
              >
                <b-form-file
                    v-model="image"
                    name="image"
                    accept=".jpg, .png, .jpeg"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('Select an image')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-card-text>
              <span>{{ $t('Description')}} </span>
            </b-card-text>
            <b-form-group>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('Description')"
                  rules="required"
              >
                <vue-editor v-model="home_page.description" name="description"
                            :state="errors.length > 0 ? false:null"
                            :placeholder="$t('Write description here')" :editorToolbar="customToolbar" rows="5" ></vue-editor>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- submit button -->
          <b-col md="12" class="mt-2">
            <b-button
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
            >
              Submit
            </b-button>
          </b-col>
        </b-row>
      </form>
    </validation-observer>

  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BRow, BCol, BButton,BCardText,BFormGroup,BFormFile,
} from 'bootstrap-vue'
import {
  required,email,
} from '@validations'
import apiCall from "@/libs/axios";
import { VueEditor } from "vue2-editor";
export default {
  name:'HomePage',
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,BFormFile,
    BRow,BFormGroup,
    BCol,
    BButton,
    BCardText,VueEditor,
  },
  data() {
    return {
      required,email,
      image:null,
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        [{ color: [] }]
      ],
    }
  },
  created(){
      this.$store.dispatch('GET_ALL_HOME_PAGE');
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let data =new FormData(this.$refs.contact_information_form);
          data.append('description',this.home_page.description);
          apiCall.post('/home/page/information/update/store',data).then((response)=>{
            if (response.data.status==='success'){
              this.$toaster.success(response.data.message);
              //this.$refs.contact_information_form.reset();
              this.image=null;
              this.$refs.simpleRules.reset();
              this.$store.dispatch('GET_ALL_HOME_PAGE');
            }else {
              this.$toaster.error(response.data.message);
            }
          }).catch((error)=>{
            if (error.response.status ==422) {
              Object.keys(error.response.data.errors).map((field) => {
                this.$toaster.error(error.response.data.errors[field][0]);
              })
            }
            else this.$toaster.error(error.response.data.message);
          });
        }
      })
    },
  },
  computed:{
    home_page(){
      return this.$store.getters.home_page;
    }
  }
}
</script>